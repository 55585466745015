import axios from '@/utils/axios';

/**
 * Ретрансляции
 * */


/**
 * Получение ретрансляций
 * */
export const getAllRebroadcast = async payload => {
    const { data } = await axios({
        method: 'POST',
        url: `/admin_query`,
        data: payload
    });
    return data ?? null;
};
