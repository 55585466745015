import { defineStore } from 'pinia';
import { Integrators } from '@/api/integrators.js';

export const integratorsStore = defineStore('storeIntegrators', {
  state: () => ({
    allIntegratorsFiltered: [],
    allIntegrators: [],
    allIntegratorsMax: 0,
    allDealerIntegratorsFiltered: [],
    allDealerIntegrators: [],
    allDealerIntegratorsMax: 0,
    integratorCompanyId: null,
    allIntegratorsList: [],
    sortField: '',
    sortDesc: ''
  }),

  actions: {
    async postIntegratorInfo({ from = 0, count = 100, value = '' }, pagination) {
      const sortData = {};
      if (this.sortDesc !== '') {
        sortData.sortDesc = this.sortDesc;
        sortData.sortField = this.sortField;
      }
      try {
        if (!this.allIntegrators.length || pagination) {
          const response = await Integrators.postIntegratorInfo({ from, count, value, ...sortData });
          this.allIntegrators = response.list;
          this.allIntegratorsFiltered = response.list;
          this.allIntegratorsMax = response.max;
          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    getAllIntegratorsList({ from = 0, count = 100, value = '' }) {
      return new Promise((resolve) => {
        if (!this.allIntegratorsList.length) {
          Integrators.postIntegratorInfo({ from, count, value }).then((res) => {
            this.allIntegratorsList = res.list;

            if (res.max > this.allIntegratorsList.length) {
              const max = res.max;
              Integrators.postIntegratorInfo({
                from,
                count: max,
                value
              }).then((response) => {
                this.allIntegratorsList = response.list;
              });
            }
            resolve(res);
          });
        } else {
          resolve();
        }
      });
    },
    async sideFilterIntegrators(formData) {
      try {
        const response = await Integrators.sideFilterIntegrators(formData);
        this.allIntegratorsFiltered = response.list;
        this.allIntegratorsMax = response.max;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async editIntegrators(data) {
      try {
        const response = await Integrators.editIntegrators(data);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async deleteIntegrator(id) {
      try {
        const response = await Integrators.deleteIntegrator(id);
        this.allIntegratorsFiltered = this.allIntegrators.filter((val) => val.id !== id);
        this.allIntegratorsMax -= 1;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async filterIntegratorInfo(data) {
      try {
        const response = await this.postIntegratorInfo({ from: 0, count: 100, value: data.value }, true);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async postIntegratorDealer(data) {
      try {
        const response = await Integrators.postIntegratorDealer(data);
        this.allDealerIntegrators = response.list;
        this.allDealerIntegratorsFiltered = response.list;
        this.allDealerIntegratorsMax = response.max;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async filterDealerIntegrator(data) {
      try {
        const response = await this.postIntegratorDealer({ from: 0, count: 100, value: data.value, id: data.id });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async WEBSOCKET_CHANGE({ id, operation }) {
      if (operation === 'DELETE') return this.DELETE_INTEGRATOR(id);

      const data = await Integrators.getIntegrator(id);
      if (operation === 'RESTORE') {
        this.ADD_INTEGRATOR(data);

        return remoteObjectsS.restoreObject(data);
      }

      operation === 'POST'
        ? this.ADD_INTEGRATOR(data)
        : this.UPDATE_INTEGRATOR(data);
    },

    async getIntegrator(id) {
      try {
        const response = await Integrators.getIntegrator(id);
        this.integratorCompanyId = response.company.id;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    ADD_INTEGRATOR(dealer) {
      if (!this.allIntegratorsFiltered.some((x) => x.id === dealer.id)) {
        this.allIntegrators = this.allIntegratorsFiltered = [...this.allIntegratorsFiltered, dealer];
      }
    },
    UPDATE_INTEGRATOR(dealer) {
      const idx = this.allIntegratorsFiltered.findIndex((elem) => elem.id === dealer.id);

      if (idx === -1) return;

      this.allIntegrators = this.allIntegratorsFiltered = [
        ...this.allIntegratorsFiltered.slice(0, idx),
        dealer,
        ...this.allIntegratorsFiltered.slice(idx + 1)
      ];
    },
    DELETE_INTEGRATOR(id) {
      this.allIntegrators = this.allIntegratorsFiltered =
        this.allIntegratorsFiltered.filter((item) => item.id !== id);
    },
    updateSortedIntegrators(payload) {
      this.allIntegratorsFiltered = payload.list;
    },

    async unblockIntegrators(id, params) {
      try {
        return await Integrators.unblockIntegrators(id, params);
      } catch (error) {
        return error;
      }
    },
  },

  getters: {
    getAllIntegrators: (state) => state.allIntegratorsFiltered,
    getDealerAllIntegrators: (state) => state.allDealerIntegratorsFiltered,
    getIntegratorCompanyId: (state) => state.integratorCompanyId
  }
});
