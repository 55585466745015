import auth from '@/modules/auth/module';
import dashboard from '@/modules/dashboard/module';
import home from '@/modules/home/module';
import company from '@/modules/company/module';
import users from '@/modules/users/module';
import commandTemplates from '@/modules/commandTemplates/module';
import globalSearch from '@/modules/globalSearch/module';
import payments from '@/modules/payments/module';
import integrators from '@/modules/integrators/module';
import object from '@/modules/object/module';
import rebroadcasts from '@/modules/rebroadcasts/module';
import rebroadcastsHD from '@/modules/rebroadcastsHD/module';
import feedback from '@/modules/feedback/module';
import remoteObjects from '@/modules/remoteObjects/module';
import terminals from '@/modules/terminals/module';
import invoices from '@/modules/invoices/module';

const modules = [
  auth,
  dashboard,
  home,
  company,
  users,
  commandTemplates,
  globalSearch,
  integrators,
  payments,
  object,
  rebroadcasts,
  rebroadcastsHD,
  feedback,
  remoteObjects,
  terminals,
  invoices
];

export default modules;
