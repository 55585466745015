import { defineStore } from 'pinia';
import { remoteObjectsStore } from './remoteObjects.js';
import { ObjectApi } from '@/api/object.js';
import { Company } from '@/api/company.js';

import {RebroadcastsService} from "@/services/rebroadcastService";


export const objectStore = defineStore('storeObject', {
  state: () => ({
    clearInformation: false,
    currentObjectId: '',
    unitsGroupItems: [],
    objectUsers: [],
    objectUsersFiltered: [],
    allObjectMax: 0,
    params: [],
    //NEW UNITS
    units: [],
    activeObject: null,
    activeObjectCouplings: [],
    activeObjectNewCouplings: [],
    activeObjectShifts: [],
    activeObjectNewShifts: [],
    activeObjectFields: [],
    modalType: '',
    errors: [],
    sensorTableError: false,
    ports: [],
    custom_fields: [],
    objectCopySensors: [],
    selectedSensor: null,
    invalidElements: [],
    filter: {
      object: null,
      integrator: null,
      companies: [],
      protocol: [],
      sensorType: []
    },
    count: 0,
    sortField: '',
    sortDesc: ''
  }),

  actions: {
    async postAllObjects(
      {
        from = 0,
        count = 100,
        companies = [],
        value,
        field
      },
      pagination
    ) {
      console.log('ARGS', arguments)

      try {
        if (!this.objectUsers.length || pagination) {
          const requestData = {
            from,
            count,
            value,
            field
          };

          if (this.filter.integrator) {
            requestData.dealer = { id: this.filter.integrator };
          }

          if (this.filter.companies.length) {
            requestData.companies = this.filter.companies.map((id) => {
              return { id };
            });
          }

          if (this.sortDesc !== '') {
            requestData.sortDesc = this.sortDesc;
            requestData.sortField = this.sortField;
          }

          if (this.filter.sensorType.length || this.filter.protocol.length) {
            requestData.conditions = [];
          }

          if (this.filter.sensorType.length) {
            requestData.conditions.push({ field: 'sensors.type_key', values: this.filter.sensorType });
          }

          if (this.filter.protocol.length) {
            requestData.conditions.push({ field: 'terminal_type_key', values: this.filter.protocol });
          }

          const response = await ObjectApi.postAllObjects(requestData);

          this.objectUsers = response.list;
          this.objectUsersFiltered = response.list;
          this.allObjectMax = response.max;
          this.units = response.list;

          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },

    async getUnitsGroup() {
      try {
        if (!this.unitsGroupItems.length) {
          const response = await ObjectApi.getUnitsGroup();
          this.unitsGroupItems = response.list.map((item, index) => ({
            index,
            ...item
          }));
          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async getObject() {
      try {
        if (!this.objectUsers.length) {
          const response = await ObjectApi.getObject();

          this.objectUsers = response.list;
          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async deleteObject(ids) {
      try {
        const response = await ObjectApi.deleteObject(ids);
        ids.forEach((id) => {
          this.objectUsers.forEach((val, key) => {
            if (val.id === id) {
              this.objectUsers.splice(key, 1);
              this.allObjectMax -= 1;
            }
          });
        });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async deleteObjects(ids) {
      try {
        const response = await ObjectApi.deleteObjects(ids);
        ids.forEach((id) => {
          this.objectUsers.forEach((val, key) => {
            if (val.id === id) {
              this.objectUsers.splice(key, 1);
              this.allObjectMax -= 1;
            }
          });
        });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async analyzeWialon({ id, file }) {
      try {
        const response = await ObjectApi.analyzeWialon({ id, file });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async paramsUnitSensor(key) {
      try {
        const response = await ObjectApi.getParamsForUnit();
        let paramsObject = {};
        for (let param of response) {
          paramsObject[Object.keys(param)] = Object.values(param)[0]
            .split(', ')
            .map((item) => {
              return { value: item, label: item };
            });
        }
        this.params = paramsObject;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async analyzeParams({ id, terminals }) {
      try {
        const response = await ObjectApi.analyzeParams({ id, terminals });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async createParams({ id, info }) {
      try {
        const response = await ObjectApi.createParams({ id, info });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async filterObject(data) {
      const requestData = {
        from: 0,
        count: 100,
        value: data.value,
        field: data.valueFilter?.value || ''
      };

      if (this.filter.integrator) {
        requestData.dealer = { id: this.filter.integrator };
      }

      if (this.filter.companies.length) {
        requestData.companies = this.filter.companies.map((id) => {
          return { id };
        });
      }

      if (this.sortDesc !== '') {
        requestData.sortDesc = this.sortDesc;
        requestData.sortField = this.sortField;
      }

      if (this.filter.sensorType.length || this.filter.protocol.length) {
        requestData.conditions = [];
      }

      if (this.filter.sensorType.length) {
        requestData.conditions.push({ field: 'sensors.type_key', values: this.filter.sensorType });
      }

      if (this.filter.protocol.length) {
        requestData.conditions.push({ field: 'terminal_type_key', values: this.filter.protocol });
      }

      try {
        return await this.postAllObjects(requestData, true);
      } catch (e) {
        throw new Error(e);
      }
    },

    // NEW UNITS
    async getObjectById(id) {
      try {
        const data = await ObjectApi.getObjectById(id);

        this.SET_ACTIVE_OBJECT(JSON.parse(JSON.stringify(data)));
      } catch (err) {
        throw Error(err);
      }
    },

    async createObject(formData) {
      try {
        const data = await ObjectApi.createObject({ formData });

        const company = await Company.getCompanyById(data.company_id);

        this.CREATE_UNIT({
          ...data,
          terminal_type_key: data.terminal_type.key,
          company_name: company.name
        });
        this.SET_ACTIVE_OBJECT({
          ...data,
          company_name: company.name
        });
      } catch (err) {
        throw new Error(err);
      }
    },

    async updateObject(formData) {
      try {
        const data = await ObjectApi.updateObject({
          id: formData.id,
          formData
        });

        this.UPDATE_UNIT(data);
        this.SET_ACTIVE_OBJECT(data);
      } catch (err) {
        throw new Error(err);
      }
    },

    async validateObject({ formData, activeTab }) {
      try {
        await ObjectApi.validateObject({ formData });

        this.SET_ERRORS([]);
      } catch (err) {
        this.SET_ERRORS([activeTab]);
        throw Error(err);
      }
    },

    async getPorts() {
      try {
        const data = await ObjectApi.getPorts();

        this.ports = data;
      } catch (err) {
        throw Error(err);
      }
    },

    async clearObjectsFilters() {
      this.filter.companies = [];
      this.filter.integrator = null;
      this.filter.protocol = [];
      this.filter.sensorType = [];

      const clearData = {
        companies: [],
        count: 100,
        field: '',
        fields: [],
        from: 0,
        model: 'units',
        value: '',
        conditions: []
      };

      try {
        await this.postAllObjects(clearData, true);
      } catch (error) {
        console.error('Error during clearing objects:', error);
      }
    },
    async postFileToObject(file,companyId) {
      try {
        const response = await ObjectApi.postFileToObject(file,companyId);
        return response
      } catch (err) {
        throw Error(err);
      }
    },
    CREATE_UNIT(unit) {
      if (!this.units.some((x) => x.id === unit.id)) {
        this.units = [...this.units, unit];
      }

      if (!this.objectUsersFiltered.some((x) => x.id === unit.id)) {
        this.objectUsersFiltered = [...this.objectUsersFiltered, unit];
      }
    },

    async getCustomFieldsData() {
      const data = await ObjectApi.getCustomFields();

      this.getCustomFields = data;
    },

    SET_ACTIVE_OBJECT(payload) {
      this.activeObject = payload;
    },
    SET_SENSORS_ACTIVE_OBJECT(payload) {
      this.activeObject.sensors = payload;
    },
    SET_SELECTED_SENSOR(sensor) {
      this.selectedSensor = sensor;
    },
    SET_SENSOR_CALIBRATION(data) {
      const idx = this.activeObject.sensors.findIndex(
        (item) => item.id === data.id
      );

      this.activeObject.sensors[idx][data.table] = data.values;
    },
    SET_SENSOR_PARAM_CALIBRATION(data) {
      const idx = this.activeObject.sensors.findIndex(
        (item) => item.id === data.id
      );

      this.activeObject.sensors[idx][data.param][data.table] = data.values;
    },
    SET_CUSTOM_FIELDS_ACTIVE_OBJECT(payload) {
      this.activeObject.custom_fields = payload;
      this.custom_fields = payload;
    },
    SET_SENSOR_CUSTOM_FIELDS({ id, values }) {
      const idx = this.activeObject.sensors.findIndex((item) => item.id === id);

      this.activeObject.sensors[idx].custom_fields = values;
    },
    DELETE_SENSOR_CUSTOM_FIELD({ id, itemIdx }) {
      const idx = this.activeObject.sensors.findIndex((item) => item.id === id);

      this.activeObject.sensors[idx].custom_fields.splice(itemIdx, 1);
    },
    ADD_SENSOR_CUSTOM_FIELD({ id, item }) {
      const idx = this.activeObject.sensors.findIndex((item) => item.id === id);

      this.activeObject.sensors[idx].custom_fields.push(item);
    },
    CHANGE_SENSOR_CUSTOM_FIELD({ id, index, prop, value }) {
      const idx = this.activeObject.sensors.findIndex((item) => item.id === id);

      this.activeObject.sensors[idx].custom_fields[index][prop] = value;
    },
    SET_COUPLINGS_ACTIVE_OBJECT(payload) {
      this.activeObjectCouplings = payload;
    },
    SET_NEW_COUPLINGS_ACTIVE_OBJECT(payload) {
      this.activeObjectNewCouplings = payload;
    },
    SET_SHIFTS_ACTIVE_OBJECT(payload) {
      this.activeObjectShifts = payload;
    },
    SET_NEW_SHIFTS_ACTIVE_OBJECT(payload) {
      this.activeObjectNewShifts = payload;
    },
    SET_SERVICES_ACTIVE_OBJECT(payload) {
      this.activeObject.services = payload;
    },
    SET_FIELDS_ACTIVE_OBJECT(payload) {
      this.activeObjectFields = payload;
    },
    ADD_FIELD_ACTIVE_OBJECT(payload) {
      this.activeObject.custom_fields.push(payload);
    },
    DELETE_FIELD_ACTIVE_OBJECT(localId) {
      this.activeObjectFields = this.activeObjectFields.filter(
        (item) => item.localId !== localId
      );
      this.activeObject.custom_fields = this.activeObject.custom_fields.filter(
        (item) => item.localId !== localId
      );
    },
    SET_MODAL_TYPE(type) {
      this.modalType = type;
    },
    SET_ERRORS(error) {
      this.errors = error;
    },
    SET_SENSORS_ERRORS(error) {
      this.sensorTableError = error;
    },

    async unitsItemSensors(id) {
      try {
        const response = await ObjectApi.unitsItemSensors(id);
        this.objectCopySensors = response;

        return response
      } catch (e) {
        throw new Error(e);
      }
    },

    ADD_CONNECTED_SENSOR({ sensorId, id }) {
      const idx = this.activeObject.sensors.findIndex(
        (item) => item.id === sensorId
      );

      this.activeObject.sensors[idx].connected_sensors.push({ id });
    },
    DELETE_CONNECTED_SENSOR({ sensorId, id }) {
      const idx = this.activeObject.sensors.findIndex(
        (item) => item.id === sensorId
      );

      this.activeObject.sensors[idx].connected_sensors =
        this.activeObject.sensors[idx].connected_sensors.filter(
          (item) => item.id !== id
        );
    },

    async WEBSOCKET_CHANGE({ id, operation }) {
      if (operation === 'DELETE') return this.DELETE_UNIT(id);

      const { list } = await ObjectApi.postAllObjects({
        from: 0,
        count: 1,
        value: id
      });

      if (operation === 'RESTORE') {
        const remoteObjectsS = remoteObjectsStore();

        this.ADD_UNIT(list[0]);

        return remoteObjectsS.restoreObject(list[0]);
      }

      operation === 'POST' ? this.ADD_UNIT(list[0]) : this.UPDATE_UNIT(list[0]);
    },

    ADD_UNIT(object) {
      if (!this.objectUsersFiltered.some((x) => x.id === object.id)) {
        this.objectUsers = this.objectUsersFiltered = [
          ...this.objectUsersFiltered,
          object
        ];
      }
    },

    UPDATE_UNIT(object) {
      const idx = this.objectUsersFiltered.findIndex(
        (elem) => elem.id === object.id
      );

      if (idx === -1) return;

      this.objectUsers = this.objectUsersFiltered = [
        ...this.objectUsersFiltered.slice(0, idx),
        object,
        ...this.objectUsersFiltered.slice(idx + 1)
      ];
    },

    DELETE_UNIT(id) {
      this.objectUsers = this.objectUsersFiltered =
        this.objectUsersFiltered.filter((item) => item.id !== id);
    },

    SET_IS_TEXT_CONVERTING({ id, value }) {
      const idx = this.activeObject.sensors.findIndex((item) => item.id === id);

      this.activeObject.sensors[idx].is_string_value = value;
    },

    SET_SENSOR_MAX_MIN_BORDER({ type, id, value }) {
      const idx = this.activeObject.sensors.findIndex((item) => item.id === id);

      this.activeObject.sensors[idx][`${type}_valid`] = value;
    },
    SET_INVALID_ELEMENTS(value) {
      this.invalidElements = value;
    },
    updateSortedObjects(payload) {
      this.objectUsersFiltered = payload.list;
    },

    CLEAR_OBJECT_FILTER() {
      console.log('CLEAR_OBJECT_FILTER');
      this.filter.object = null;
      this.filter.integrator = null;
      this.filter.companies = [];
      this.filter.protocol = [];
      this.filter.sensorType = [];
    },

    IMPORT_LOAD_HISTORY_WLN(payload) {
      return ObjectApi.postImportHistoryWln(payload);
    },

    async searchObjects(payload) {
      this.objectUsersFiltered = await RebroadcastsService.getRebroadcastsByIMEI(payload);
    },

    clearObjectUsers() {
      this.objectUsers = [];
    },
  },

  getters: {
    getObjectUsers: (state) =>
      state.objectUsersFiltered ? state.objectUsersFiltered : state.objectUsers,
    getAllObject: (state) => state.objectUsersFiltered,
    getParams: (state) => state.params,
    getParamsByKey: (state) => (key) => {
      return state.params[key];
    },
    activeObjectGetter: (state) => state.activeObject,
    activeObjectCouplingsGetter: (state) => state.activeObjectCouplings,
    activeObjectNewCouplingsGetter: (state) => state.activeObjectNewCouplings,
    activeObjectShiftsGetter: (state) => state.activeObjectShifts,
    activeObjectNewShiftsGetter: (state) => state.activeObjectNewShifts,
    activeObjectFieldsGetter: (state) => state.activeObjectFields,
    modalTypeGetter: (state) => state.modalType,
    errorsGetter: (state) => state.errors,
    sensorTableErrorGetter: (state) => state.sensorTableError,
    portsGetter: (state) => state.ports,
    allCustomFieldsGetter: (state) => state.custom_fields,
    customFieldGetter: (state) => (key) => {
      return state.custom_fields.find((field) => field.key === key);
    },
    getObjectCopySensors: (state) => state.objectCopySensors,
    invalidElementsGetter: (state) => state.invalidElements
  }
});
