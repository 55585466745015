<template>
  <div class="layout">
    <layout-header
      :data="me"
      @openConfig="isConfigOpen = true"
    />
    <div class="layout__row">
      <div class="layout__item">
        <uiInput
          v-model="searchValue"
          type="text"
          icon-color="#A6AEB8"
          search
          height="36px"
          :placeholder="'Поиск по модулям'"
          width="420px"
          class="module-search"
        />
      </div>
      <router-link
        v-for="(filteredLink, index) in filteredLinks"
        :key="index"
        class="layout__item"
        :to="filteredLink.to"
        @click="handleRouterClick(filteredLink)"
      >
        <template v-if="!filteredLink.isHidden">
          <UiIcon
            color="#FFFFFF"
            :value="filteredLink.icon"
            :width="filteredLink.iconWidth"
            :height="filteredLink.iconHeight"
          />
          <div class="layout__item-name">
            {{ $t(`${filteredLink.translationKey}`) }}
          </div>
        </template>
      </router-link>
    </div>
    <ModalConfig v-model="isConfigOpen" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { userStore } from '@/store/modules/user.js';
import { commandsTemplatesStore } from '@/store/modules/commandsТemplates.js';
import { integratorsStore } from '@/store/modules/integrators';
import { searchStore } from '@/store/modules/globalSearch';

import LayoutHeader from '@/components/layouts/navigationDrawer/header.vue';
import UiInput from '@/components/ui/input.vue';
import UiIcon from '@/components/ui/icon.vue';
import ModalConfig from '@/components/modals/config.vue';

const store = userStore();
const storeTemplates = commandsTemplatesStore();
const storeIntegrators = integratorsStore();
const storeGlobalSearch = searchStore();
const { locale } = useI18n();
const router = useRouter();
const role = ref('');
const isConfigOpen = ref(false)

const { t } = useI18n();
const me = computed(() => store.me);
const searchValue = ref('');

const links = ref([
  {
    to: '/global-search',
    icon: 'eye-search',
    translationKey: 'side_bar.global_search',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/company',
    icon: 'companies',
    translationKey: 'side_bar.companies',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/integrators',
    icon: 'star',
    translationKey: 'side_bar.integrators',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/payments',
    icon: 'payments',
    translationKey: 'side_bar.payments',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/users',
    icon: 'users',
    translationKey: 'side_bar.users',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/object',
    icon: 'terminals',
    translationKey: 'side_bar.objects',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/terminals',
    translationKey: 'side_bar.terminals',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/command-templates',
    translationKey: 'side_bar.command_templates',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/rebroadcasts',
    translationKey: 'side_bar.rebroadcasts',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/rebroadcasts-history-data',
    translationKey: 'side_bar.rebroadcastsHD',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/remote-objects',
    translationKey: 'side_bar.remote_objects',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  },
  {
    to: '/invoices',
    translationKey: 'side_bar.accounts',
    isHidden: false,
    iconWidth: '18px',
    iconHeight: '18px'
  }
]);

const filteredLinks = computed(() => {
  const searchTerm = searchValue.value.toLowerCase();
  if (searchTerm === '') {
    return links.value.filter(link => !link.isHidden);
  }
  return links.value.filter(link =>
      t(link.translationKey).toLowerCase().includes(searchTerm) && !link.isHidden
  );
});

const updateLinksTranslation = () => {
  links.value = links.value.map(link => ({
    ...link,
    translation: t(link.translationKey)
  }));
};

watch(locale, updateLinksTranslation, { immediate: true });
watch(searchValue, () => updateLinksTranslation());

store.getMe().then((res) => {
  locale.value = res.lang.key;
  role.value = res.role.key;

  if (res?.need_change_password) {
    router.push('/auth/changePassword');
  } else {
    store.getAllProperties();
    store.getAllConfigs();
    storeTemplates.getDictionaries();
    storeIntegrators.postIntegratorInfo({}, false);
    storeIntegrators.getAllIntegratorsList({});
  }
});

const clearStorageSideFilters = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith('filter')) {
      localStorage.removeItem(key);
    }
  }
};

const handleRouterClick = (link) => {
  storeGlobalSearch.setFilterActive(false);
  clearStorageSideFilters();
  if (searchValue.value) {
    searchValue.value = '';
  }
};
</script>


<style scoped lang="scss">
.module-search {
  color: white;
  background-color: #343434;

  :deep(input) {
    color: white !important;
    background-color: #343434;
  }
}

.layout {
  width: 320px;
  min-width: 320px;
  height: 100vh;
  background-color: #292929;
  box-sizing: border-box;

  &__row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 4px;
    padding: 12px 10px 0;
    box-sizing: border-box;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 11px 0 12px 10px;
    width: 100%;
    transition: 0.3s;
    box-sizing: border-box;
    column-gap: 8px;
    cursor: pointer;

    svg {
      transition: 0.3s all;
    }

    &:hover {
      background: #343434;
      border-radius: 8px;

      svg {
        color: #5c9deb !important;
      }
    }

    &:hover > .layout__item-name {
      color: #5c9deb;
    }

    &-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #fff;
      transition: 0.3s;
    }
  }

  .router-link-active {
    .layout__item-name {
      color: #5c9deb;
    }

    svg {
      color: #5c9deb !important;
    }
  }

  .active {
    .layout__item-name {
      color: #5c9deb;
    }

    svg {
      color: #5c9deb !important;
    }
  }
}
</style>
