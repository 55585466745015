<template>
  <UiDialog
    v-if="showDialog"
    v-model="showDialog"
    width="480px"
    :outside="false"
  >
    <div class="block-company">
      <h1 class="block-company__title">
        {{ $t('blocked.company.notify') }}
      </h1>
      <div class="divider" />
      <div class="block-company__text">
        <p class="text">
          <template v-if="isSupervisorOrTariffEditor">
            {{ $t('blocked.company.text', { companyName }) }}
            <br> <br>
            {{ $t('blocked.company.text_two', { companyName } ) }} <br>
            {{ $t('blocked.company.text_three', { companyName } ) }}.
          </template>
          <template v-else>
            {{ $t('blocked.company.text.user', { companyName } ) }}
          </template>
        </p>
        <!-- <button
          class="button btn"
          @click="unblock"
        >
          BLOCK/unblock
        </button> -->
        <div
          v-if="isSupervisorOrTariffEditor"
          class="links"
        >
          <a
            class="link"
            href="tel:88001017543"
          >
            8-800-101-75-43</a>
          <a
            class="link"
            href="tel:89261303009"
          >8-926-130-30-09</a>
          <a
            class="link link-mail"
            href="mailto:info@skif.pro"
          >info@skif.pro</a>
        </div>
      </div>
    </div>
  </UiDialog>
</template>

<script setup>
import { toRef, ref, computed, defineProps, defineEmits } from 'vue';
import UiDialog from '@/components/ui/dialog';
import { userStore } from '@/store/modules/user.js';
import { integratorsStore } from '@/store/modules/integrators';

const storeIntegrators = integratorsStore();

const UserStore = userStore();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  role: {
    type: Object,
    default: () => ({}),
  },
});

const getRoles = computed(() => {
  return UserStore.getRoles;
});

const infoMe = computed(() => {
  return UserStore.$state.me;
});

const showDialog = toRef(props, 'modelValue');
const emit = defineEmits(['update:modelValue']);

const companyName = computed(() => {
  return infoMe.value.active_company.name;
});

const isSupervisorOrTariffEditor = computed(() => {
  return getRoles.value.some(el => el.key === 'SUPERVISOR' || el.key === 'TARIFF_EDITOR');
})

const isUnblock = ref(false);
const unblock = async () => {
  await storeIntegrators.unblockIntegrators(infoMe.value.active_company.dealer.id, isUnblock.value);
};

</script>

<style lang="scss" scoped>
.block-company {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  background: #FFF;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06), 0 8px 40px rgba(0, 0, 0, 0.04);
  flex-direction: column;
  box-sizing: border-box;
  &__title {
    color: #C93131;
    font-size: 24px;
    font-weight: 700;
    margin: 20px;
    margin-bottom: 16px;
  }
  &__text {
    .text {
      margin: 20px;
      margin-top: 16px;
      color: #292929;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
    }

    .links {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .link {
        display: block;
        margin: 0 20px;
        color: #3A7CCA;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        text-decoration: underline;
      }

      .link-mail {
        margin-top: 16px;
      }
    }
  }
}

.divider {
  margin-left: -20px;
  width: calc(100% + 40px);
  height: 1px;
  background: #cdd4dd;
}
</style>
