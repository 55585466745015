import { getAllRebroadcast } from '@/api/v1/rebroadcasts';
import { ITEMS_SMALL_COUNT } from "@/utils/constants.js";
export class RebroadcastsService {
  static async getRebroadcastsByIMEI(name) {
    try {
      const payload = {
        value: name,
        field: 'name',
        model: 'units',
        from: 0,
        count: ITEMS_SMALL_COUNT,
      };
      const { list } = await getAllRebroadcast(payload);
      return list;
    } catch (error) {
      console.error('Error fetching rebroadcasts:', error);
      throw error;
    }
  }
}
